import { navigate } from 'gatsby';
import { extractUrlFromMultiPicker } from 'utils/umbracoControlsUtils';

const tiktokEmbed = '{{tiktokEmbed}}';
const instagramEmbed = '{{instagramEmbed}}';
const tiktokEmbedScript = 'https://www.tiktok.com/embed.js';
const instagramEmbedScript = 'https://www.instagram.com/embed.js';

export const isEmpty = (obj): boolean => {
  if (obj === undefined || obj === null) {
    return true;
  }
  if (Array.isArray(obj)) {
    return !obj.length;
  }
  if (typeof obj === 'object') {
    return !Object.keys(obj).length;
  }

  return !obj;
};

export const navigateToPage = (link: PageContent.LinkType[] | string): any => {
  if (typeof link === 'string') {
    navigate(link);
  } else {
    link && navigate(extractUrlFromMultiPicker(link));
  }
};

export const getPathForLanguage = (
  langUrls: PageContent.LangUrlProps[],
  iso: string,
  urls?: PageContent.LangUrlProps[]
): string =>
  (
    langUrls.find(({ lang }) => lang.toLowerCase() === iso.toLowerCase()) ||
    urls?.find(({ lang }) => lang.toLowerCase() === iso.toLowerCase())
  )?.url || '';

export const isEmbedScriptExists = (contents: string, embedTag: string) => {
  return contents.includes(embedTag);
};

export const getEmbedScripts = (content: string): [string[], string] => {
  const embedScripts: string[] = [];
  let updatedContent = content;

  if (isEmbedScriptExists(content, instagramEmbed)) {
    embedScripts.push(instagramEmbedScript);
    updatedContent = updatedContent.replace(instagramEmbed, '');
  }

  if (isEmbedScriptExists(content, tiktokEmbed)) {
    embedScripts.push(tiktokEmbedScript);
    updatedContent = updatedContent.replace(tiktokEmbed, '');
  }

  return [embedScripts, updatedContent];
};

export const getLangString = (langString: string) => {
  const secondPart = langString.split('-')[1];

  return langString.replace(/-/g, '_').replace(secondPart, secondPart.toUpperCase());
};

export const isExists = (text: string, searchText: string) => {
  return text.includes(searchText);
};

export const isCurrentCategory = (categoryName: string, currentCategory: string) => {
  return categoryName === currentCategory;
};

export const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

// To identify element is in view port or not
export const isElementInViewport = (elementBottom: number) => {
  return elementBottom <= window.innerHeight;
};

const utilFunctions = {
  isEmpty,
  navigateToPage,
  getPathForLanguage,
  getEmbedScripts,
  getLangString,
  isExists,
  scrollToRef,
  isElementInViewport,
  isCurrentCategory,
};

export default utilFunctions;
