import React, { FC, useState } from 'react';

import { useStaticQuery, Link, graphql } from 'gatsby';
import classNames from 'classnames';

import './FooterLinks.scss';
import { toBoolean } from 'utils/stringUtils/stringUtils';
import { extractUrlFromMultiPicker, extractNameFromMultiPicker } from 'utils/umbracoControlsUtils';
import { FooterType, FooterLinkProps } from './models';

const FooterLinks: FC<FooterLinkProps> = ({ langProps }) => {
  const {
    allFooter: { nodes },
  }: FooterType = useStaticQuery(graphql`
    {
      allFooter {
        nodes {
          lang
          footerBottomMenu {
            properties {
              linkVisibility
              label
              url {
                name
                url
              }
              subnavigationLinks {
                properties {
                  label
                  url {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const [showList, setShowList] = useState(false);

  const { footerBottomMenu } = nodes.filter(({ lang }) => lang === langProps.lang)[0];
  const isLinkVisible = footerBottomMenu.map(({ properties: { linkVisibility } }) =>
    toBoolean(linkVisibility)
  );

  return (
    <div className="footer-links" data-testid="footerLinks">
      {footerBottomMenu.map(({ properties: { label, url, subnavigationLinks } }, i) =>
        subnavigationLinks && subnavigationLinks.length > 0 && isLinkVisible[i] ? (
          <li
            key={label}
            className="footer-links__link dropdown"
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            onClick={() => setShowList(!showList)}
          >
            <span>
              {label}
              <i className="arrow" />
            </span>
            {showList && subnavigationLinks && (
              <ul className="footer__dropdown">
                {subnavigationLinks?.map(
                  ({ properties: { label: subnavLabel, url: subnavUrl } }) => (
                    <li>
                      <Link
                        className="footer-links__link"
                        key={subnavLabel}
                        to={extractUrlFromMultiPicker(subnavUrl)}
                      >
                        {subnavLabel}
                      </Link>
                    </li>
                  )
                )}
              </ul>
            )}
          </li>
        ) : (
          <Link
            className={classNames('footer-links__link', {
              'footer-links__link--hidden': !isLinkVisible[i],
            })}
            key={extractNameFromMultiPicker(url)}
            to={extractUrlFromMultiPicker(url)}
          >
            {extractNameFromMultiPicker(url)}
          </Link>
        )
      )}
    </div>
  );
};

export default FooterLinks;
