import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';

import { ToPascalCaseFunctionType, ToBooleanFunctionType } from './models';

export const toPascalCase: ToPascalCaseFunctionType = (str) =>
  startCase(camelCase(str)).replace(/ /g, '');

export const toBoolean: ToBooleanFunctionType = (str) => Boolean(Number(str));

export const getLanguage = (str: string): string => str.split('-')[0];

const stringUtils = {
  toPascalCase,
  toBoolean,
  getLanguage,
};

export default stringUtils;
