import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Logo from 'common/Logo';
import ScreenRecognitionContext from 'utils/context/screenRecognitionContext';
import SocialMedia from 'common/SocialMedia';
import { FooterNavProps, FooterType } from './models';

import './FooterNav.scss';
import MARKUP_CLASSNAMES from './constants';

const FooterNav: FC<FooterNavProps> = ({
  isCustomLogo,
  ariaLabel,
  socialMedia: { socialMediaItems },
  brandSettings: { brandLogo, customBrandLogo, brandName, url: homepageLink },
  langProps,
}) => {
  const {
    allFooter: { nodes },
  }: FooterType = useStaticQuery(graphql`
    {
      allFooter {
        nodes {
          lang
          nav {
            properties {
              url {
                name
                url
              }
            }
          }
        }
      }
    }
  `);

  const { nav } = nodes.filter(({ lang }) => lang === langProps.lang)[0];

  const screenRecognitionContext = useContext(ScreenRecognitionContext);

  const links = nav.map(({ properties: { url } }) => url[0]);

  return (
    <div className="main-footer">
      <div className="main-footer__top row" data-testid="main-footer-top">
        {!screenRecognitionContext.isMobile ? (
          <div className="main-footer__logo col--md1">
            <Logo {...{ brandLogo, customBrandLogo, brandName, homepageLink, isCustomLogo }} />
          </div>
        ) : null}
        <div
          className={classNames('main-footer__nav', {
            'col--md9': !screenRecognitionContext.isMobile,
            'flex-column': screenRecognitionContext.isMobile,
          })}
          aria-label={ariaLabel}
        >
          {links.map(({ url, name }) => (
            <Link className="main-footer__nav-link" key={name} to={url}>
              {name}
            </Link>
          ))}
        </div>
        <div className={`main-footer__social-media ${MARKUP_CLASSNAMES.socialMedia}`}>
          <SocialMedia socialMediaItems={socialMediaItems} />
        </div>
      </div>
    </div>
  );
};

export default FooterNav;
