import React, { FC } from 'react';
import { graphql } from 'gatsby';
import BreadcrumbsItem from './BreadcrumbsItem';
import { BreadcrumbsProps } from './models';

import './Breadcrumbs.scss';

const Breadcrumbs: FC<BreadcrumbsProps> = ({
  breadcrumbs: { breadCrumb, breadcrumbsCustomText },
}) => {
  return (
    <div data-testid="breadcrumbs" className="breadcrumbs">
      <div className="breadcrumbs__wrapper">
        <ol>
          {breadCrumb!.map((breadcrumb, index) => (
            <BreadcrumbsItem
              key={breadcrumb.crumbLabel}
              {...breadcrumb}
              current={index === breadCrumb!.length - 1}
            />
          ))}
        </ol>
        {breadcrumbsCustomText ? <h2>{breadcrumbsCustomText}</h2> : null}
      </div>
    </div>
  );
};

export default Breadcrumbs;

export const query = graphql`
  fragment FragmentAllPageSite on SitePageConnection {
    nodes {
      context {
        breadcrumb {
          location
          crumbs {
            pathname
            crumbLabel
          }
        }
        areBreadcrumbsDisplayed
        breadcrumbName
      }
    }
  }
`;
