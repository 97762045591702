import React, { FC, useState } from 'react';
import { toBoolean } from 'utils/stringUtils/stringUtils';

import HeaderNav from './HeaderNav';
import HeaderTop from './HeaderTop';
import { HeaderProps } from './models';

const Header: FC<HeaderProps> = ({
  isCustomLogo,
  brandSettings,
  socialMedia,
  langProps,
  searchResultsPage,
  isHeaderTopVisible,
}) => {
  const [isMobileNavOpened, setMobileNavOpened] = useState(false);

  const headerNavProps = {
    isCustomLogo,
    isMobileNavOpened,
    setMobileNavOpened,
    brandSettings,
    socialMedia,
    langProps,
    searchResultsPage,
  };

  const headerTopProps = {
    socialMedia,
    langProps,
  };

  return (
    <header className="main-header">
      {toBoolean(isHeaderTopVisible) ? <HeaderTop {...headerTopProps} /> : null}
      <HeaderNav {...headerNavProps} />
    </header>
  );
};

export default Header;
