import React, { FC } from 'react';
import SearchBox from 'components/Header/HeaderSearch';
import { DesktopNavProps } from './models';
import NavMenu from '../NavMenu';

import './DesktopNav.scss';

const DesktopNav: FC<DesktopNavProps> = ({
  header: { navigation, searchboxPlaceholder, searchboxAriaLabel },
  lang,
  searchResultsPage,
}) => (
  <>
    <nav className="main-nav">
      <NavMenu classPrefix="main" navigation={navigation} />
    </nav>
    <SearchBox
      placeholder={searchboxPlaceholder}
      ariaLabel={searchboxAriaLabel}
      {...{ lang, searchResultsPage }}
    />
  </>
);

export default DesktopNav;
