import React, { FC, useContext } from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import './HeaderNav.scss';

import ScreenRecognitionContext from 'utils/context/screenRecognitionContext';
import Logo from 'common/Logo';
import { HeaderType, HeaderNavProps } from './models';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';

const HeaderNav: FC<HeaderNavProps> = ({
  isCustomLogo,
  setMobileNavOpened,
  isMobileNavOpened,
  socialMedia,
  brandSettings: { brandLogo, customBrandLogo, brandName, url },
  langProps,
  searchResultsPage,
}) => {
  const { allHeader }: HeaderType = useStaticQuery(graphql`
    {
      allHeader {
        nodes {
          navigation {
            properties {
              label
              url {
                url
              }
              isDropdown
              subnavigationLinks {
                properties {
                  label
                  url {
                    url
                    name
                    queryString
                  }
                }
              }
            }
          }
          lang
          openMenuAriaLabel
          closeMenuAriaLabel
          searchboxPlaceholder
          searchboxAriaLabel
        }
      }
    }
  `);

  const header = allHeader.nodes.filter(({ lang }) => lang === langProps.lang)[0];
  const screenRecognitionContext = useContext(ScreenRecognitionContext);

  const mobileNavProps = {
    setMobileNavOpened,
    isMobileNavOpened,
    header,
    socialMedia,
    langProps,
    searchResultsPage,
  };

  return (
    <div className="main-header__bottom" data-testid="navbar">
      <div className="wrapper main-header__bottom-items">
        <div className="main-header__content">
          <Logo {...{ brandLogo, customBrandLogo, brandName, homepageLink: url, isCustomLogo }} />
          {screenRecognitionContext.isXl ? (
            <DesktopNav {...{ header, lang: langProps.lang, searchResultsPage }} />
          ) : (
            <MobileNav {...mobileNavProps} />
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderNav;
