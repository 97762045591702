import React, { FC, useContext, useEffect, useState } from 'react';
import Button from 'common/Button';
import { SearchContext } from 'utils/context/searchContextProvider';
import classNames from 'classnames';
import { SearchProps } from './models';
import './Search.scss';

const Search: FC<SearchProps> = ({
  placeholder,
  ariaLabel,
  lang,
  searchResultsPage,
  setMobileNavOpened,
  className,
}) => {
  const searchContext = useContext(SearchContext);
  const [searchText, setSearchText] = useState('');
  const [link, setLink] = useState('/');
  const onFormSubmit = (e) => {
    e.preventDefault();
    setSearchText('');
    searchContext.searchHandler(searchText, `${link}?q=${searchText}`);

    if (setMobileNavOpened) {
      setMobileNavOpened(Boolean((prevIsMobileNavOpened) => !prevIsMobileNavOpened));
    }
  };

  useEffect(() => {
    searchResultsPage.nodes.forEach(({ url, langProps: { lang: searchLang } }) => {
      if (lang === searchLang) {
        setLink(url);
      }
    });
  }, [lang, searchText]);

  return (
    <div className={classNames('search-box', className)} data-testid="search">
      <form className="search-box__form" onSubmit={onFormSubmit}>
        <label className="search-box__label" htmlFor="search">
          <input
            type="search"
            inputMode="search"
            className="search-box__input"
            id="search"
            value={searchText}
            placeholder={placeholder}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </label>
        <Button {...{ ariaLabel, className: 'search-box__button', type: 'submit' }} />
      </form>
    </div>
  );
};

export default Search;
