import React, { FC, useContext } from 'react';

import ScreenRecognitionContext from 'utils/context/screenRecognitionContext';
import SocialMedia from 'common/SocialMedia';
import LanguageSelector from 'components/LanguageSelector';
import classNames from 'classnames';
import { MEN_CATEGORY_PATH } from 'utils/constants';
import { isExists } from 'utils/functions';
import { isBrowser } from 'utils/browser';
import { HeaderTopProps } from './models';

import './HeaderTop.scss';

const HeaderTop: FC<HeaderTopProps> = ({ socialMedia: { socialMediaItems }, langProps }) => {
  const screenRecognitionContext = useContext(ScreenRecognitionContext);

  return screenRecognitionContext.isXl ? (
    <div
      className={classNames('main-header__top', {
        'men--banner-top': isBrowser() && isExists(window.location.pathname, MEN_CATEGORY_PATH),
      })}
      data-testid="header-top"
    >
      <div className="wrapper wrapper--main-header__top-items">
        <SocialMedia socialMediaItems={socialMediaItems} />
        <LanguageSelector {...{ langProps }} />
      </div>
    </div>
  ) : null;
};

export default HeaderTop;
