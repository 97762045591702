import React, { FC } from 'react';
import { Link } from 'gatsby';

import { BreadcrumbsItemProps } from './models';

import './BreadcrumbsItem.scss';

const BreadcrumbsItem: FC<BreadcrumbsItemProps> = ({ current, pathname, crumbLabel }) => (
  <>
    {!current ? (
      <li className="breadcrumbs__item">
        <Link to={pathname}>{crumbLabel}</Link>
        <span
          className="breadcrumbs__icon"
          aria-hidden="true"
          data-testid="breadcrumbs-separator"
        />
      </li>
    ) : (
      <li className="breadcrumbs__item breadcrumbs__item--current">{crumbLabel}</li>
    )}
  </>
);

export default BreadcrumbsItem;
