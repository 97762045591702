import React, { FC, useEffect, useState } from 'react';
import { toBoolean } from 'utils/stringUtils/stringUtils';
import { getHexColor } from 'utils/umbracoControlsUtils';
import { getCurrentLocationHref } from 'utils/browser';
import classNames from 'classnames';
import { SocialMediaProps } from './models';

import './SocialMedia.scss';

const SocialMedia: FC<SocialMediaProps> = ({
  socialMediaItems,
  ignoreUmbracoStyling = false,
  socialMediaTitle,
}) => {
  const [currentLocation, setCurrentLocation] = useState<null | string>('');

  useEffect(() => setCurrentLocation(getCurrentLocationHref), []);

  const visibleSocialMediaItems = socialMediaItems.filter((item) =>
    toBoolean(item.properties.isVisible)
  );

  const onMouseEnter = (e, colorObj: PageContent.ColorPickerType | undefined) => {
    if (colorObj && !ignoreUmbracoStyling) {
      e.currentTarget.style.color = getHexColor(colorObj);
    }
  };

  const onMouseLeave = (e, colorObj: PageContent.ColorPickerType) => {
    if (!ignoreUmbracoStyling) {
      e.currentTarget.style.color = getHexColor(colorObj);
    }
  };

  return (
    <div className="social-media" data-testid="social-media">
      {socialMediaTitle ? <p className="social-media__title">{socialMediaTitle}</p> : null}
      <ul className="social-media__list">
        {visibleSocialMediaItems.map(
          ({
            properties: {
              socialMediaAriaLabel,
              socialMediaIcon,
              socialMediaHoverIconColor,
              socialMediaIconColor,
              socialMediaLink,
              socialMediaShareLink,
            },
          }) => {
            const { name, url, target } = socialMediaLink[0] || {};

            return (
              <li
                className={classNames('social-media__link-item', {
                  'social-media__link-item--share': socialMediaShareLink,
                })}
                key={name || socialMediaShareLink[0]}
              >
                <a
                  href={url || `${socialMediaShareLink[0]}${currentLocation}`}
                  aria-label={socialMediaAriaLabel}
                  target={target || '_blank'}
                  rel="noopener noreferrer"
                  style={!ignoreUmbracoStyling ? { color: getHexColor(socialMediaIconColor) } : {}}
                  onMouseEnter={(e) => onMouseEnter(e, socialMediaHoverIconColor)}
                  onMouseLeave={(e) => onMouseLeave(e, socialMediaIconColor)}
                >
                  <i className={`fab fa-${socialMediaIcon}`} />
                </a>
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
};

export default SocialMedia;
