import React, { FC } from 'react';
import classNames from 'classnames';
import { ButtonProps } from './models';

import './Button.scss';

const Button: FC<ButtonProps> = ({
  children,
  ariaLabel,
  onClick,
  className,
  type = 'button',
  isReversed,
  disabled = false,
  ...rest
}) => (
  <button
    disabled={disabled}
    // eslint-disable-next-line react/button-has-type
    type={type}
    aria-label={ariaLabel}
    onClick={onClick}
    className={classNames('btn', className, {
      'btn--reverse': isReversed,
    })}
    {...rest}
  >
    {children}
  </button>
);

export default Button;
