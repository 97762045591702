import React, { FC } from 'react';
import { Link } from 'gatsby';

import UmbracoImage from 'common/Image/UmbracoImage';

import { VideoHeaderType } from './models';

import './videoHeader.scss';

const VideoHeader: FC<VideoHeaderType> = ({
  brandLogo,
  brandName,
  homepageLink,
  brandLogoBackground,
}) => (
  <header className="video-header">
    <div className="video-header__logo" data-testid="video-brand-logo">
      <Link to={homepageLink}>
        <UmbracoImage image={brandLogo} alt={brandName} className="video-header__logo-img" />
      </Link>
    </div>
    <UmbracoImage
      image={brandLogoBackground}
      alt={brandName}
      className="video-header__logo-background"
      objectFit="contain"
    />
  </header>
);

export default VideoHeader;
