import React, { FC } from 'react';
import SearchBox from 'components/Header/HeaderSearch';
import Button from 'common/Button';
import classNames from 'classnames';
import SocialMedia from 'common/SocialMedia';
import LanguageSelector from 'components/LanguageSelector';
import NavMenu from '../NavMenu';
import { MobileNavProps } from './models';

import './MobileNav.scss';

const MobileNav: FC<MobileNavProps> = ({
  setMobileNavOpened,
  isMobileNavOpened,
  header: {
    navigation,
    closeMenuAriaLabel,
    openMenuAriaLabel,
    searchboxPlaceholder,
    searchboxAriaLabel,
  },
  socialMedia: { socialMediaItems },
  langProps,
  searchResultsPage,
}) => {
  const mobileNavToggleClasses = classNames({
    'mobile-nav mobile-nav--opened': isMobileNavOpened,
    'mobile-nav mobile-nav--closed': !isMobileNavOpened,
  });

  return (
    <>
      <Button
        className="mobile-nav__toggle mobile-nav__open"
        ariaLabel={openMenuAriaLabel}
        onClick={() => {
          setMobileNavOpened((prevIsMobileNavOpened) => !prevIsMobileNavOpened);
        }}
      />
      <div className="mobile-nav" data-testid="mobile-nav">
        <nav className={mobileNavToggleClasses}>
          <div className="mobile-nav__panel">
            <NavMenu classPrefix="mobile" navigation={navigation} />
            <div className="mobile-nav__content">
              <SearchBox
                placeholder={searchboxPlaceholder}
                ariaLabel={searchboxAriaLabel}
                lang={langProps.lang}
                {...{ searchResultsPage }}
                setMobileNavOpened={setMobileNavOpened}
              />
              <LanguageSelector {...{ langProps }} />
              <SocialMedia socialMediaItems={socialMediaItems} ignoreUmbracoStyling />
            </div>
          </div>
        </nav>
        <Button
          className="mobile-nav__toggle mobile-nav__close"
          ariaLabel={closeMenuAriaLabel}
          onClick={() => {
            setMobileNavOpened((prevIsMobileNavOpened) => !prevIsMobileNavOpened);
          }}
        />
      </div>
    </>
  );
};

export default MobileNav;
