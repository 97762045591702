import React, { FC } from 'react';
import { isExists } from 'utils/functions';
import { MEN_CATEGORY_PATH } from 'utils/constants';
import { isBrowser } from 'utils/browser';
import { FooterProps } from './models';

import './Footer.scss';
import FooterNav from './FooterNav';
import FooterLinks from './FooterLinks';
import Copyright from './Copyright';

const Footer: FC<FooterProps> = ({ brandSettings, socialMedia, langProps, isCustomLogo }) => {
  const footerNavProps = {
    isCustomLogo,
    brandSettings,
    socialMedia,
    langProps,
  };

  return (
    <footer
      className={`${
        isBrowser() && isExists(window.location.pathname, MEN_CATEGORY_PATH) ? 'banner-men' : ''
      }`}
      data-testid="footer"
    >
      <FooterNav {...footerNavProps} />
      <FooterLinks {...{ langProps }} />
      <Copyright {...{ langProps }} />
    </footer>
  );
};

export default Footer;
