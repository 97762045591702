import { createContext } from 'react';

const ScreenRecognitionContext = createContext({
  isXS: false,
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  isXl: false,
} as PageContent.ScreenResolutionType);

export default ScreenRecognitionContext;
