import React, { FC } from 'react';

import { DangerouslySetInnerHtmlProps } from './models';

const DangerouslySetInnerHtml: FC<DangerouslySetInnerHtmlProps> = ({ html, element, ...rest }) => {
  const Tag = element || 'div';

  return html ? <Tag dangerouslySetInnerHTML={{ __html: html }} {...rest} /> : null;
};

export default DangerouslySetInnerHtml;
