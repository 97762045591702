import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { extractUrlFromMultiPicker } from 'utils/umbracoControlsUtils';
import { NavMenuProps } from './models';

const NavMenu: FC<NavMenuProps> = ({ classPrefix, navigation }) => {
  const [showList, setShowList] = useState(false);

  return (
    <ul className={`${classPrefix}-nav__navigation-list`} data-testid="navigation-list">
      {navigation.map(({ properties: { label, url, subnavigationLinks, isDropdown } }) =>
        subnavigationLinks?.length && isDropdown ? (
          <li
            className={`${classPrefix}-nav__link-container dropdown`}
            key={`${classPrefix}-nav-item-${label}`}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            onClick={() => setShowList(!showList)}
            data-testid="navigation-item"
            tabIndex={0}
          >
            <span>
              {label}
              <i className="dropdown-arrow" />
            </span>
            {showList ? (
              <ul className={`${classPrefix}-nav__subnav-list dropdown-menu`}>
                {subnavigationLinks?.map(
                  ({ properties: { label: subnavLabel, url: subnavUrl } }) => (
                    <li key={`subnav-item-${subnavLabel}`}>
                      <Link
                        aria-label={label}
                        to={extractUrlFromMultiPicker(subnavUrl)}
                        className={`${classPrefix}-nav__subnav-link link`}
                      >
                        {subnavLabel}
                      </Link>
                    </li>
                  )
                )}
              </ul>
            ) : null}
          </li>
        ) : (
          <li
            className={`${classPrefix}-nav__link-container`}
            key={`${classPrefix}-nav-item-${label}`}
            data-testid="navigation-item"
          >
            <Link
              aria-label={label}
              to={`${extractUrlFromMultiPicker(url)}`}
              className={classNames(`${classPrefix}-nav__link link`, {
                'mobile-nav__subnav-arrow': subnavigationLinks?.length,
              })}
              data-testid="navigation-link"
              activeClassName="active"
              partiallyActive
            >
              {label}
            </Link>
            {subnavigationLinks?.length ? (
              <ul className={`${classPrefix}-nav__subnav-list`}>
                {subnavigationLinks?.map(
                  ({ properties: { label: subnavLabel, url: subnavUrl } }) => (
                    <li
                      className={`${classPrefix}-nav__subnav-link-container`}
                      key={`subnav-item-${subnavLabel}`}
                    >
                      <Link
                        aria-label={label}
                        to={`${extractUrlFromMultiPicker(subnavUrl)}`}
                        className={`${classPrefix}-nav__subnav-link link`}
                      >
                        {subnavLabel}
                      </Link>
                    </li>
                  )
                )}
              </ul>
            ) : null}
          </li>
        )
      )}
    </ul>
  );
};

export default NavMenu;
