import React, { FC } from 'react';
import { Link } from 'gatsby';

import UmbracoImage from 'common/Image/UmbracoImage';
import { LogoProps } from './models';

const Logo: FC<LogoProps> = ({
  brandLogo,
  customBrandLogo,
  brandName,
  homepageLink,
  isCustomLogo,
}) => (
  <div className="logo" data-testid="brand-logo">
    <Link to={homepageLink}>
      {!isCustomLogo ? (
        <UmbracoImage image={brandLogo} alt={brandName} className="logo__img" />
      ) : (
        <UmbracoImage image={customBrandLogo} alt={brandName} className="logo__img" />
      )}
    </Link>
  </div>
);

export default Logo;
