import React, { FC } from 'react';
import classNames from 'classnames';

import { IPlainImage } from './models';

const PlainImage: FC<IPlainImage> = ({
  className,
  srcSetWebp,
  srcSet,
  sizes,
  src,
  alt,
  isLocalImage,
  lazyLoad,
}) => {
  const CSSclassNames = classNames(className, 'plain-image', {
    'plain-image--umbraco': !isLocalImage,
    'gatsby-image-wrapper': isLocalImage,
  });

  return (
    <div className={CSSclassNames}>
      <picture>
        <source type="image/webp" srcSet={srcSetWebp || srcSet} sizes={sizes} />
        <img
          className="plain-image__img"
          sizes={sizes}
          srcSet={srcSet}
          src={src}
          alt={alt}
          loading={lazyLoad ? 'lazy' : 'eager'}
        />
      </picture>
    </div>
  );
};

export default PlainImage;
