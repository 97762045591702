import { useState, useLayoutEffect } from 'react';
import {
  DESKTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
  XL_BREAKPOINT,
  XS_BREAKPOINT,
} from 'utils/hooks/constants';
import { UseScreenRecognitionHook } from './types';

const useScreenRecognition: UseScreenRecognitionHook = () => {
  const [isXS, setXS] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isXl, setIsXl] = useState(false);

  const handleResize = () => {
    const width = window.innerWidth;
    setXS(width < XS_BREAKPOINT);
    setIsMobile(width < TABLET_BREAKPOINT);
    setIsTablet(width >= TABLET_BREAKPOINT && width < DESKTOP_BREAKPOINT);
    setIsDesktop(width >= DESKTOP_BREAKPOINT && width < XL_BREAKPOINT);
    setIsXl(XL_BREAKPOINT <= width);
  };

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isXS,
    isMobile,
    isTablet,
    isDesktop,
    isXl,
  };
};

export default useScreenRecognition;
