export const extractUrlFromMultiPicker = (urlObject: PageContent.LinkType[]): string => {
  return urlObject && urlObject[0] && urlObject[0].url
    ? `${urlObject[0].url}${urlObject[0].queryString || ''}`
    : '/';
};

export const extractNameFromMultiPicker = (urlObject: PageContent.LinkType[]): string => {
  return urlObject && urlObject[0] && urlObject[0].name ? urlObject[0].name : '';
};

export const getHexColor = (color: PageContent.ColorPickerType): string => {
  return color && `#${color.value}`;
};

export const getDropDownItem = (items?: string[]) => items && items[0];

const umbracoControlsUtils = {
  extractUrlFromMultiPicker,
  getHexColor,
  getDropDownItem,
};

export default umbracoControlsUtils;
